import * as React from "react"
import './custom.sass'
import { PageProps } from "gatsby"
import App from "./app"


const IndexPage = ({}: PageProps) => (
  <App/>
)

export default IndexPage